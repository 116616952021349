<template>
  <section>
    <b-row>
      <b-col>
        <ag-grid-table
          ref="optimization-comments-table"
          :configUrl="commentsConfig"
          :dataUrl="commentsData"
        >
          <template v-slot:footer>
            <div style="margin-top: 2rem">
              <b-input-group>
                <b-form-input
                  v-model="nuevo_comentario"
                  :placeholder="$t('Ingresar nuevo comentario...')"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    @click="nuevoComentario"
                    variant="outline-primary"
                    :disabled="nuevo_comentario.trim().length <= 0"
                    >{{ $t("Guardar Comentario") }}</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </div>
          </template>
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template> 
    
<script>
import CardTooltip from '@/views/brunacomponents/wrappers/CardTooltipWrapper.vue';
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import Stat from './detalle-semanal/components/Stat.vue'
import HeaderCellRenderer from '@/views/brunacomponents/ag-grid-table/cell-renderers/HeaderCellRenderer.vue';
import { AgGridVue } from "ag-grid-vue";
import axios from '@axios'
import
{
  BTabs,
  BTab,
  BOverlay,
  BSpinner,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormTags,
  BListGroup,
  BListGroupItem
} from "bootstrap-vue";
var qs = require('qs');
import useApiServices from '@/services/useApiServices.js';
export default {
  data()
  {
    //let optimization_id = this.$route.query.optimization_id;
    let query = qs.stringify({ optimization_id: this.invocation_id });
    return {
      showOverlay: false,
      nuevo_comentario: '',
      commentsConfig: useApiServices.invocations.comments.historico.config + "?" + query,
      commentsData: useApiServices.invocations.comments.historico.data + "?" + query
    }
  },

  props: {
    receta: String,
    invocation_id: String,
  },
  mounted()
  {
    //let optimization_id = this.$route.query.optimization_id;
    let query = qs.stringify({ invocation_id: this.invocation_id })
    console.log("commment");
    console.log(query);
  },

  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    BrunaElementLoader,
    AgGridTable,
    AgGridVue,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    Stat,
    BFormTags,
    BListGroup,
    BListGroupItem,
    headerCellRenderer: HeaderCellRenderer,
    CardTooltip
  },
  methods: {
    nuevoComentario()
    {
      let invocation_id = this.invocation_id;
      useApiServices.postInvocationCreateComment(invocation_id, { body: this.nuevo_comentario })
        .then((response) =>
        {
          this.$refs['optimization-comments-table'].refreshRows([], false)
          this.nuevo_comentario = ""
        })
        .catch((error) =>
        {
        });
    }
  },
};
    </script>



  
  <style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
    
  <style >
.btn-padding {
  margin-right: 48px;
}

.icon-plan {
  padding-top: 5%;
}
.ag-row-pinned {
  background-color: #f0e68c !important;
}
</style>
    
  
  <style>
div.ag-watermark {
  display: none !important;
}
</style>