<template>
  <div>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          variant="flat-secondary"
          class="btn-icon float-right"
          v-b-toggle.sidebar-1
        >
          <feather-icon icon="HelpCircleIcon" size="20" />
        </b-button>
      </b-col>

      <b-sidebar
        id="sidebar-1"
        title="Resultado de planificación"
        shadow
        bg-variant="dark"
        text-variant="light"
        width="450px"
        right
        backdrop
      >
        <div class="px-2 py-2"></div>
      </b-sidebar>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title>Recetas planificadas</b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div>

            <!-- Card Actions -->
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li>
                  <div>
                    <b-button-toolbar
                      aria-label="Toolbar with button groups and input groups"
                      justify
                    >
                      <b-button-group style="margin-bottom: 1rem">
                        <b-button
                          size="sm"
                          :disabled="disableCompareRuns"
                          @click="compareOptimizations()"
                          variant="primary"
                          >Comparar</b-button
                        >
                      </b-button-group>
                    </b-button-toolbar>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- Card Body  -->
          <b-card-body>
            <ag-grid-vue
              style="width: 100%; height: 500px"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :rowData="rowData"
              :pinnedTopRowData="pinnedTopRows"
              rowSelection="multiple"
              rowMultiSelectWithClick="true"
              @selection-changed="onSelectionChanged"
              @grid-ready="onGridReady"
            >
          </ag-grid-vue>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="compareRecipesResumeSpider">
        </bruna-element-loader>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="compareRecipesResume1">
        </bruna-element-loader>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="compareRecipesResume2">
        </bruna-element-loader>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="compareRecipesResume3">
        </bruna-element-loader>
      </b-col>
    </b-row>

    <CommentsRecipes
       :invocation_id="invocation_id"
      >
    </CommentsRecipes>
      <!-- <template #overlay>
        <div class="text-center">
          <b-spinner label="Loading..." />
          <p id="cancel-label">Cargando Resultados...</p>
        </div>
      </template> -->


  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import RepeatStatistics from "@/views/clients/pf/components/RepeatStatistics";
import axios from "@axios";
import router from '@/router'
var qs = require('qs');

import { AgGridVue } from "ag-grid-vue";
import useApiServices from "@/services/useApiServices.js";
import BlendingActions from "./BlendingActions-delete.vue"
import ActionsCellRenderer3 from "@/views/brunacomponents/ag-grid-table/cell-renderers/ActionsCellRender3.vue";
import HeaderCellRenderer from '@/views/brunacomponents/ag-grid-table/cell-renderers/HeaderCellRenderer.vue';
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import CommentsRecipes from './CommentsRecipes.vue';



export default {
  data() {
    return {
      invocation_id: this.$route.query.optimization_id,
      detalleSemanalConfig: "",
      detalleSemanalData: "",
      compareRecipesResumeSpider: "",
      compareRecipesResume1: "",
      compareRecipesResume2: "",
      compareRecipesResume3: "",

      itemsData: [],
      semana: "",
      disableCompareRuns: true,
      disableRellenar: true,
      selectedRows: [],
      enableCellChangeFlash: true,

      recetasTable: useApiServices.recetasTable,
      itemsData: [],
      columnDefs: [],
      rowData: [],
      pinnedTopRows: [],

      liveReload: false,


      getRowNodeId: (params) =>
        {
          return params.id;
        },
      rowClassRules: {
        'escenario-base': (params) =>
        {
          if (params.data == undefined)
          {
            return false;
          }
          return params.data.default === 1;
        }
      },
      gridApi: null,
      gridColumnApi: null,
      historicoOptimizationsConfig: useApiServices.historicoOptimizationsConfig,
      historicoOptimizationsData: useApiServices.historicoOptimizationsData,
      showOverlay: false,


    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BButtonToolbar,
    BButtonGroup,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    RepeatStatistics,
    AgGridVue,
    BrunaElementLoader,
    BlendingActions: BlendingActions,
    headerCellRenderer: HeaderCellRenderer,
    customActionsCellRender3: ActionsCellRenderer3,
    CommentsRecipes
  },

  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {

    let optimization_id = this.$route.query.optimization_id;
    let q = this.$route.query;

    let query = qs.stringify({
        optimization_id: optimization_id,
    })
    
    axios
      .get(useApiServices.detallesSemanalStatics + "/" + this.semana)
      .then((response) => {
        this.itemsData = response.data;
      })
      .catch((error) => {});

      axios.request({
        method: "get",
        url: useApiServices.recetasTable + "?" + query,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) =>
        { 
          console.log(response.data);
          let columnDefs = response.data.columnDefs;

          columnDefs.push({
            flex: 1,
            cellEditor: null,
            cellEditorParams: [],
            cellEditorPopup: false,
            cellRenderer: 'customActionsCellRender3',
            cellRendererParams: {
              actions: [
                { name: "Ver Detalles", icon: "EyeIcon", action: this.accionVerDetalles },
              ]
            },
            editable: false,
            field: "",
            headerCheckboxSelection: false,
            headerName: this.$t("Acciones"),
            headerTooltip: "Tooltip",
            initialSort: null,
            menuTabs: [],
            pinned: "right",
            sortable: false,
            cellStyle: { border: 'none' }
          })

          this.columnDefs = columnDefs;
          this.rowData = response.data.rowData;
          this.pinnedTopRows = response.data.pinnedTopRows;

        })
  },

  beforeMount() {
    this.semana = this.$route.params.semana;
    this.detalleSemanalConfig = useApiServices.detalleSemanalConfig;
    this.detalleSemanalData = useApiServices.detalleSemanalData + "/" + this.semana;
    this.compareRecipesResumeSpider =  useApiServices.recetasResumeChartSpider + "?semana=" +  this.semana;
    this.compareRecipesResume1 =  useApiServices.recetasResumeChart + "?receta=1&semana=" +  this.semana;
    this.compareRecipesResume2 =  useApiServices.recetasResumeChart + "?receta=2&semana=" +  this.semana;
    this.compareRecipesResume3 =  useApiServices.recetasResumeChart + "?receta=3&semana=" +  this.semana;


  },

  methods: {
    accionVerDetalles(data)
    {
      router.push({ path: `/pf/blending/planificacion/historico/detalle/semana/` + this.semana + `/receta/` + data.data.receta + `?id=` + this.$route.query.optimization_id })
      
    },

    compareOptimizations()
    {
      this.$router.push({ name: 'compare-recipe', params: { semana: this.$route.params.semana }, query: { ids: this.selectedRows.map(run => run.receta), id: this.$route.query.optimization_id  } })
    },


    onGridReady(params) 
    {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    
    onSelectionChanged(selectedRows)
    {

      this.selectedRows = this.gridApi.getSelectedRows()
      this.disableCompareRuns = (this.selectedRows.length < 2)
      this.disableRellenar = !(this.selectedRows.length == 1)
    },
  },
};
</script>

<style scoped>
span {
  font-size: 14px;
}
</style>

<style lang="scss" scoped>
 @import "~ag-grid-community/dist/styles/ag-grid.css";
 @import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
          
<style >
  .btn-padding {
    margin-right: 48px;
  }

  .m-estadisticas {
    margin-left: 20px;
    margin-bottom: 1%;
    margin-top: 1%;
  }
  .icon-plan {
    padding-top: 5%;
  }

  .ag-row-pinned {
    background-color: #f0e68c !important;
  }
  </style>
            
          
          <style>
  div.ag-watermark {
    display: none !important;
  }
</style>