<template>
  <section>
    <b-tabs>
      <b-tab title="Detalle Semanal">
        <detalle-semanal-tab-vue></detalle-semanal-tab-vue>
      </b-tab>
    </b-tabs>
    <b-button :to="{ name: 'pf-historico-planificacion', params: {} }" style="float:right; margin-right: 15px; background-color: #053743 !important; height: 50px; ">Ir al Histórico de Planificaciones</b-button>
  </section>
</template>

<script>
import {
BCardTitle,
BFormDatepicker,
BCalendar,
BRow,
BCol,
BCardBody,
BCardText,
BCard,
BLink,
BButton,
BTabs,
BTab,



} from "bootstrap-vue";

import useApiServices from '@/services/useApiServices.js';
import BrunaElementLoader from "@/views/brunacomponents/BrunaElementLoader.vue";
import DetalleSemanalTabVue from "./DetalleSemanalTab.vue";

export default {
  components: {

    BFormDatepicker,
    BRow,
    BCol,
    BCardTitle,
    BrunaElementLoader,
    BCardTitle,
    BCardBody,
    BCardText,
    BCard,
    BLink,
    BButton,
    BTabs,
    BTab,
    BCalendar,
    DetalleSemanalTabVue,
  },

  data() {
    return {
      detallesPlanificacion: useApiServices.detallesPlanificacion,
    }
  }
};
</script>


